import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../utils/AuthContext";
import Header from "../../components/Header/Header";

const BFCTestFileUpload = ({ baseURL }) => {
    const [socketStatus, setSocketStatus] = useState("Disconnected");
    const [messages, setMessages] = useState([]);
    const selectedFilesRef = useRef([]); // Need ref since accessed in onmessage
    const [fileProgresses, setFileProgresses] = useState({});
    const [fileStatuses, setFileStatuses] = useState({});
    const [fileChunks, setFileChunks] = useState({}); // Store number of chunks per file
    const [fileUrls, setFileUrls] = useState({}); // Store uploaded file URLs
    const socketRef = useRef(null);
    const fileUploadsRef = useRef({}); // Map of fileId to upload state
    const { sessionToken } = useAuth();
    const [withChat, setWithChat] = useState(false);
    const [chatId, setChatId] = useState("");

    useEffect(() => {
        console.log("fileUploadsRef.current", fileUploadsRef.current);
    }, [fileUploadsRef.current])

    useEffect(() => {
        console.log("fileUrls", fileUrls);
    }, [fileUrls])

    const connectSocket = () => {
        const socket = new WebSocket(
            baseURL?.includes("dev")
                ? "wss://dev.tagin.ai/bfc_health_app_file_upload"
                : "wss://prod.tagin.ai/bfc_health_app_file_upload"
        );
        setSocketStatus("Connecting...");

        socket.onopen = () => {
            setSocketStatus("Connected");
            logMessage("WebSocket connected");
            socket.send(
                JSON.stringify({
                    event: "connected",
                    user_session_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgxYjUyMjFlN2E1ZGUwZTVhZjQ5N2UzNzVhNzRiMDZkODJiYTc4OGIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWFybG8tYWktZGV2IiwiYXVkIjoibWFybG8tYWktZGV2IiwiYXV0aF90aW1lIjoxNzM3NzY1MzI2LCJ1c2VyX2lkIjoiNFpqQlh5T1JPMmRIOGpRcnMzSFhaSnZFSmlnMSIsInN1YiI6IjRaakJYeU9STzJkSDhqUXJzM0hYWkp2RUppZzEiLCJpYXQiOjE3Mzc3NjUzMjYsImV4cCI6MTczNzc2ODkyNiwiZW1haWwiOiJpbGx5YXRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImlsbHlhdGVzdEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.WVXhXwi4wuFvfcLECI2l7JljVFXdtXs-4qoUZg9AVp_culUWcHT2tnPb7cmkutDtb7wK_yFSb25UA9E2XI2KKnYtlvVhd-UkDoqHnGytjoC9_98dgSj-9d3NnlI1Ser5jiUyzzZSu1gfpiedhsejW1PU-HDzRTRcjSIFTbwLIP9oec3x8rV3TYwXjnUTdFoQWrqnpMfsT5QIVd8XPxnSwjODz-7WNKIAPMnWi10evPdq-pbvuqijxP_rUY02RkGFG3yzk3Lx1pfOV_4Ed3iL4T7zryrvPIORMfAsnjc5NR2tQ20BRNtB9CcV8EV11HFTR_zlhCTGKN_l8-GE4MwHmA",
                    ...(withChat && chatId && { chat_id: chatId })
                })
            );
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            logMessage(`Received: ${JSON.stringify(data)}`);

            if (data.event === "server:file:upload:session:created") {
                const fileId = data.file_id;
                const fileName = data.file_metadata.name;

                setFileProgresses(prev => ({ ...prev, [fileName]: 0 }));
                setFileStatuses(prev => ({ ...prev, [fileName]: 'uploading' }));
                logMessage(`File upload session created. File ID: ${fileId} for ${fileName}`);

                // Prepare file upload state
                const file = selectedFilesRef.current.find(f => f.name === fileName);
                if (file) {
                    fileUploadsRef.current[fileId] = prepareFileUpload(file);
                    console.log("fileUploadsRef.current", fileUploadsRef.current);
                    startChunkUpload(fileId);
                }
            } else if (data.event === "server:file:upload:chunk:ack") {
                const fileId = data.file_id;
                const uploadState = fileUploadsRef.current[fileId];
                if (!uploadState) {
                    logMessage(`No upload state found for file ${fileId}`);
                    return;
                }

                const { file, chunks, currentChunkIndex } = uploadState;
                const totalChunks = chunks.length;
                const progress = ((currentChunkIndex + 1) / totalChunks) * 100;

                setFileProgresses(prev => ({ ...prev, [file.name]: progress }));
                logMessage(`Chunk ${currentChunkIndex + 1}/${totalChunks} acknowledged for file ${fileId}`);

                if (currentChunkIndex < totalChunks - 1) {
                    fileUploadsRef.current[fileId].currentChunkIndex++;
                    startChunkUpload(fileId);
                } else {
                    finalizeUpload(fileId);
                }
            } else if (data.event === "server:file:upload:success") {
                const fileId = data.file_id;
                console.log("fileId", fileId);
                const uploadState = fileUploadsRef.current[fileId];
                if (!uploadState) {
                    logMessage(`No upload state found for file ${fileId}`);
                    return;
                }
                console.log("uploadState", uploadState);
                console.log("fileUrls", fileUrls);

                setFileProgresses(prev => ({ ...prev, [uploadState.file.name]: 100 }));
                setFileStatuses(prev => ({ ...prev, [uploadState.file.name]: 'completed' }));
                setFileUrls(prev => ({ ...prev, [uploadState.file.name]: data.file_url }));
                console.log("fileUrls", fileUrls);
                logMessage(`File ${fileId} uploaded successfully. URL: ${data.file_url}`);
            } else if (data.event === "server:file:upload:error") {
                const fileId = data.file_id;
                console.log("fileId", fileId);
                const uploadState = fileUploadsRef.current[fileId];
                if (!uploadState) {
                    logMessage(`No upload state found for file ${fileId}`);
                    return;
                }

                setFileStatuses(prev => ({ ...prev, [uploadState.file.name]: 'failed' }));
                socketRef.current?.send(
                    JSON.stringify({
                        event: "client:file:upload:failed",
                        file_id: fileId,
                        error: data.error
                    })
                );
                logMessage(`Error during upload of file ${fileId}: ${data.error}`);
            } else if (data.event === "server:error") {
                resetAll();
                logMessage(`WebSocket error: ${data.error}`);
            }
        };

        socket.onclose = () => {
            setSocketStatus("Disconnected");
            logMessage("WebSocket disconnected");
        };

        socket.onerror = (error) => {
            logMessage(`WebSocket error: ${error.message}`);
            setSocketStatus("Error");
        };

        socketRef.current = socket;
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files || []).slice(0, 10); // Limit to 10 files
        selectedFilesRef.current = files;
        logMessage(`Selected ${files.length} files`);

        // Calculate chunks for each file
        const chunksInfo = {};
        files.forEach(file => {
            const numChunks = Math.ceil(file.size / (1024 * 1024));
            chunksInfo[file.name] = numChunks;
            logMessage(`File: ${file.name}, Size: ${file.size} bytes, Chunks: ${numChunks}`);
        });
        setFileChunks(chunksInfo);
    };

    const startUpload = (file) => {
        const extension = getFileExtension(file.name);
        const fileMetadata = {
            name: file.name,
            type: file.type,
            extension: extension,
        };

        if (socketRef.current?.readyState === WebSocket.OPEN) {
            socketRef.current.send(
                JSON.stringify({
                    event: "client:file:upload:start",
                    file_metadata: fileMetadata,
                    file_size: file.size,
                })
            );
        }
    };

    const startChunkUpload = (fileId) => {
        const uploadState = fileUploadsRef.current[fileId];
        if (!uploadState) {
            logMessage(`No upload state found for file ${fileId}`);
            return;
        }

        const { file, chunks, currentChunkIndex } = uploadState;
        const chunk = chunks[currentChunkIndex];

        logMessage(`Starting upload of chunk ${currentChunkIndex + 1}/${chunks.length} for file ${fileId}`);

        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const base64String = btoa(
                new Uint8Array(arrayBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );

            logMessage(`Sending chunk ${currentChunkIndex + 1} data for file ${fileId}`);
            socketRef.current?.send(
                JSON.stringify({
                    event: "client:file:upload:chunk",
                    chunk_data: base64String,
                    file_id: fileId,
                })
            );
        };

        reader.onerror = (error) => {
            logMessage(`Error reading chunk ${currentChunkIndex + 1} of file ${fileId}: ${error}`);
        };

        reader.readAsArrayBuffer(chunk);
    };

    const finalizeUpload = (fileId) => {
        socketRef.current?.send(
            JSON.stringify({
                event: "client:file:upload:end",
                file_id: fileId,
            })
        );
    };

    const prepareFileUpload = (file) => {
        const CHUNK_SIZE = 1024 * 1024; // 1MB
        const chunks = [];

        for (let start = 0; start < file.size; start += CHUNK_SIZE) {
            const chunk = file.slice(start, Math.min(start + CHUNK_SIZE, file.size));
            chunks.push(chunk);
        }

        return {
            file,
            chunks,
            currentChunkIndex: 0
        };
    };

    const uploadAll = () => {
        selectedFilesRef.current.forEach(file => {
            startUpload(file);
        });
    };

    const retryUpload = (fileId) => {
        const uploadState = fileUploadsRef.current[fileId];
        console.log("uploadState", uploadState);
        if (!uploadState) {
            logMessage(`No upload state found for file ${fileId}`);
            return;
        }

        // Get the original file
        const file = uploadState.file;
        const fileName = file.name;

        // Reset progress and status
        setFileProgresses(prev => ({ ...prev, [fileName]: 0 }));
        setFileStatuses(prev => ({ ...prev, [fileName]: 'uploading' }));

        // Remove existing upload state
        delete fileUploadsRef.current[fileId];

        // Start fresh upload
        startUpload(file);

        logMessage(`Starting fresh upload for file: ${fileName}`);
    };

    const resetAll = () => {
        if (socketRef.current) {
            socketRef.current.close();
        }
        selectedFilesRef.current = [];
        setFileProgresses({});
        setFileStatuses({});
        setFileChunks({});
        setFileUrls({});
        console.log("HERE3)")
        fileUploadsRef.current = {};
        setMessages([]);
        setSocketStatus("Disconnected");
    };

    const getFileExtension = (fileName) => {
        const match = fileName.match(/\.(\w+)$/);
        return match ? match[1].toLowerCase() : null;
    };

    const logMessage = (message) => {
        setMessages((prev) => [...prev, message]);
    };

    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
            />
            <h1>Multi-File Upload Socket Test</h1>
            <p>
                <strong>WebSocket Status:</strong> {socketStatus}
            </p>
            <div style={{ marginBottom: "10px" }}>
                <label style={{ marginRight: "10px" }}>
                    <input
                        type="checkbox"
                        checked={withChat}
                        onChange={(e) => setWithChat(e.target.checked)}
                    />
                    With Chat
                </label>
                {withChat && (
                    <input
                        type="text"
                        value={chatId}
                        onChange={(e) => setChatId(e.target.value)}
                        placeholder="Enter Chat ID"
                        style={{ marginRight: "10px" }}
                    />
                )}
            </div>
            <button
                onClick={connectSocket}
                disabled={socketStatus === "Connected" || (withChat && !chatId)}
            >
                Connect WebSocket
            </button>
            <button onClick={resetAll} style={{ marginLeft: "10px" }}>
                Reset Everything
            </button>
            <hr />

            <h2>Upload Files (Max 10)</h2>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                accept="*/*"
                max="10"
            />
            <button
                onClick={uploadAll}
                disabled={socketStatus !== "Connected" || !selectedFilesRef.current?.length}
            >
                Upload All Files
            </button>

            <div style={{ marginTop: "20px" }}>
                {selectedFilesRef.current.map((file, index) => (
                    <div key={file.name} style={{ margin: "10px 0", padding: "10px", border: "1px solid #ccc" }}>
                        <p style={{ margin: "0 0 5px 0" }}>
                            <strong>{file.name}</strong> ({Math.round(file.size / 1024)} KB) - {fileChunks[file.name]} chunks
                        </p>
                        <div style={{
                            height: "20px",
                            background: "#f0f0f0",
                            borderRadius: "10px",
                            overflow: "hidden"
                        }}>
                            <div style={{
                                width: `${fileProgresses[file.name] || 0}%`,
                                height: "100%",
                                background: fileStatuses[file.name] === 'failed' ? '#ff4444' : '#4CAF50',
                                transition: "width 0.3s ease-in-out",
                                animation: fileStatuses[file.name] === 'uploading' ? 'pulse 1s infinite' : 'none'
                            }} />
                        </div>
                        <p style={{ margin: "5px 0", fontSize: "0.9em", color: "#666" }}>
                            Progress: {Math.round(fileProgresses[file.name] || 0)}%
                        </p>
                        {fileUrls[file.name] && (
                            <p style={{ margin: "5px 0", fontSize: "0.9em" }}>
                                <a href={fileUrls[file.name]} target="_blank" rel="noopener noreferrer">
                                    Download Link
                                </a>
                            </p>
                        )}
                        {fileStatuses[file.name] === 'failed' && (
                            <button
                                onClick={() => {
                                    const fileId = Object.keys(fileUploadsRef.current).find(id =>
                                        fileUploadsRef.current[id].file.name === file.name
                                    );
                                    retryUpload(fileId);
                                }}
                                style={{ marginTop: "5px" }}
                            >
                                Retry Upload
                            </button>
                        )}
                    </div>
                ))}
            </div>

            <hr />
            <h2>Messages</h2>
            <div style={{
                backgroundColor: "#f9f9f9",
                border: "1px solid #ccc",
                padding: "10px",
                height: "200px",
                overflowY: "scroll"
            }}>
                {messages.map((msg, index) => (
                    <p key={index} style={{ margin: 0 }}>
                        {msg}
                    </p>
                ))}
            </div>
            <style>
                {`
                    @keyframes pulse {
                        0% { opacity: 1; }
                        50% { opacity: 0.7; }
                        100% { opacity: 1; }
                    }
                `}
            </style>
        </div>
    );
};

export default BFCTestFileUpload;